<template>
  <DefaultLayout>
    <template v-slot:header>
      <FilterHeader
        :items="[
          $t('customers.filters.showCards'),
          $t('customers.filters.showList'),
        ]"
        :onChange="onFilterChange"
        :usePreset="false"
        :preSelectedFilter="selectedRendering"
      />
      <v-spacer></v-spacer>
      <AddButton :onClick="editItem" />
    </template>

    <v-layout>
      <v-flex>
        <CustomerManager
          ref="customerManager"
          :selectedRendering="selectedRendering"
        />
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      v-model="showCreateCustomer"
      absolute
      temporary
      right
      width="75%"
    >
      <CreateCustomer :onSuccess="goToCustomer" :onClose="hideEditor" />
    </v-navigation-drawer>
  </DefaultLayout>
</template>

<script>
import { mapGetters } from "vuex";

import DefaultLayout from "@/layouts/default.vue";
import CustomerManager from "@/components/customers/customermanager.vue";
import CreateCustomer from "@/components/customers/customercreate.vue";

import AddButton from "@/components/_common/addbutton";
import FilterHeader from "@/components/_common/filterheader";

export default {
  name: "C",
  data: function() {
    return {
      showCreateCustomer: false,
      selectedRendering: 0,
      profil: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created: function() {
    this.selectedRendering = this.user.profile.customerRendering;
  },
  components: {
    DefaultLayout,
    CustomerManager,
    CreateCustomer,
    AddButton,
    FilterHeader,
  },
  methods: {
    editItem: function() {
      this.showCreateCustomer = true;
    },
    hideEditor() {
      this.showCreateCustomer = false;
    },
    onFilterChange: function(val) {
      this.selectedRendering = val;
      this.saveProfile();
    },
    goToCustomer: function(c) {
      this.$router.push(`/customers/${c.id}`);
    },
    saveProfile: function() {
      let data = { ...this.user };
      data.profile.customerRendering = this.selectedRendering;
      this.$store
        .dispatch("setUser", data)
        .then(() => {})
        .catch((e) => {
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>
