<template>
  <v-card elevation="0">
    <PanelHeader :onSave="save" :title="$t('customers.edit.title')" :tabIndex="3999" :onClose="onClose" />
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-radio-group v-model="customer.salutation" row>
                <v-radio label="Frau" value="mrs"></v-radio>
                <v-radio label="Herr" value="mr"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.firstname" :label="$t('customers.list.firstname')" :tabIndex="3001"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.name" :rules="rules.required" :label="$t('customers.list.name')" required :tabIndex="3002"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="customer.email" :rules="rules.email" :label="$t('customers.list.email')" required :tabIndex="3003"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="customer.email2"
                :rules="rules.emailoptional"
                :label="$t('customers.list.email2')"
                :tabIndex="3004"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.phone" :label="$t('customers.list.phone')" :tabIndex="3005"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.mobile" :label="$t('customers.list.mobile')" :tabIndex="3006"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.mobile2" :label="$t('customers.list.mobile2')" :tabIndex="3007"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <DropDate :model="customer.birthdate" :tabIndex="3008" :onClose="setBirthdate" :label="$t('customers.list.birthday')" />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.birthplace" :label="$t('customers.list.birthPlace')" :tabIndex="3009"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="customer.familystatus" :label="$t('customers.list.familyStatus')" :tabIndex="3010"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete :items="sellers" v-model="customer.sellerId" :label="$t('customers.edit.seller')" clearable :tabIndex="3011"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4">
              <DropDate :model="customer.firstcontact" :tabIndex="3012" :onClose="setFirstContactDate" :label="$t('customers.list.firstContact')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3>Addressen</h3>
              <div class="addr" v-for="(addr, i) in customer.addresses" :key="i">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="addr.street" :label="$t('customers.addr.street')" :tabIndex="3020 + i * 10" hide-details dense></v-text-field>
                  </v-col>
                  <v-col cols="4" md="1">
                    <v-text-field 
                      v-model="addr.house_number"
                      :label="$t('customers.addr.houseNumber')"
                      :tabIndex="3021 + i * 10"
                      hide-details
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" md="2">
                    <v-text-field v-model="addr.post_code" :label="$t('customers.addr.postCode')" :tabIndex="3022 + i * 10" hide-details dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="addr.place" :label="$t('customers.addr.place')" :tabIndex="3023 + i * 10" hide-details dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-btn v-if="i > 0" icon @click="moveAddr(i, i - 1)" color="primary">
                      <v-icon>mdi-arrow-up-bold</v-icon>
                    </v-btn>
                    <v-btn v-if="i < customer.addresses.length - 1" icon @click="moveAddr(i, i + 1)" color="primary">
                      <v-icon>mdi-arrow-down-bold</v-icon>
                    </v-btn>
                    <v-btn icon @click="delAddr(i)" color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <v-btn text class="float-right" @click="customer.addresses.push({})">
                <v-icon>mdi-plus</v-icon>
                {{ $t("customers.addr.add") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("customers.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

import PanelHeader from "@/components/_common/panelheader";
import DropDate from "@/components/_common/dropdate";

export default {
  mixins: [formatMixin, formMixin, listMixin],

  data: function() {
    return {
      valid: false,
      menu: false,
      sellers: [],
    };
  },

  components: {
    PanelHeader,
    DropDate,
  },

  props: {
    projectId: {
      type: String,
      default: () => {},
    },
    onSuccess: {
      type: Function,
    },
    onClose: {
      type: Function,
    },
    customer: {
      type: Object,
      default: () => {
        return {
          addresses:[]
        };
      },
    },
  },

  computed: {
    seller() {
      let s = this.sellers.find((element) => element.id == this.customer.sellerId);
      if (s) return s;
      return {
        name: "Verkäufer?",
      };
    },
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/sellers`)
        .then((response) => {
          this.sellers = response.data.map((s) => {
            return { text: s.name, value: s.id };
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    load: function(params) {
      if (params && params.id) {
        axios
          .post(`/api/customers/${params.id}`)
          .then((response) => {
            this.customers = response.data;
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      } else {
        this.customer = {
          projectId: this.projectId,
        };
      }
    },

    reset: function() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
      this.customer = {
        body: "",
        start: "",
        end: "",
        name: "",
      };
    },

    setBirthdate(date) {
      this.customer.birthdate = date;
    },
    setFirstContactDate(date) {
      this.customer.firstcontact = date;
    },

    save: function() {
      if (this.$refs.form.validate()) {
        let u = { ...this.customer };
        delete u.createdAt;
        delete u.updatedAt;
        delete u.projects;

        if (!u.email2) {
          u.email2 = null;
        }
        if (!u.sellerId) {
          u.sellerId = null;
        }

        axios
          .post(`/api/customers/${u.id || ""}`, u)
          .then((res) => {
            this.$store.dispatch("showSnackMessage", { message: "Data saved." });
            this.onSuccess(res.data);
          })
          .catch((e) => {
            console.log("ERR", e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },

    goToCustomer: function(cId) {
      this.$router.push(`/customers/${cId}`);
    },

    moveAddr: function(from, to) {
      this.customer.addresses.splice(to, 0, this.customer.addresses.splice(from, 1)[0]);
    },
    delAddr: function(index) {
      this.customer.addresses.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.grpHeader {
  margin-top: 16px;
}
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}

.addr {
  border-left: 0.5rem solid grey;
  /*border-radius: 1rem;*/
  padding: 0 1rem;
  margin: 1rem 0;
}
</style>
