<template>
  <v-card min-height="200" elevation="1" class="card-outter" hover @click="goToCustomer(customer)">
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">
          {{ customer.seller ? customer.seller.name : "-" }}
        </div>
        <v-list-item-title class="headline mb-1">{{ customer.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="customer.email"><v-icon>mdi-email-outline</v-icon> {{ customer.email }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="customer.email2"><v-icon>mdi-email-outline</v-icon> {{ customer.email2 }} </v-list-item-subtitle>

        <v-list-item-subtitle v-if="customer.phone"><v-icon>mdi-phone</v-icon> {{ customer.phone }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="customer.phone2"><v-icon>mdi-phone</v-icon> {{ customer.phone2 }}</v-list-item-subtitle>

        <v-list-item-subtitle v-if="customer.mobile"><v-icon>mdi-cellphone-basic</v-icon> {{ customer.mobile }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="customer.mobile2"><v-icon>mdi-cellphone-basic</v-icon> {{ customer.mobile2 }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="20" v-if="customer.appActivated">
        <v-icon medium color="accent">mdi-apps-box</v-icon>
      </v-list-item-avatar>
      <v-list-item-avatar tile size="20" @click="setFav(customer)">
        <v-icon medium :color="customer.fav ? 'yellow' : 'grey'">mdi-star</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
</template>

<script>
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, formMixin, listMixin],

  data: function() {
    return {};
  },

  components: {},

  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    goToCustomer: {
      type: Function,
    },
    setFav: {
      type: Function,
    },
  },

  computed: {},

  created() {},

  methods: {},
};
</script>
<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
