<template>
  <div>
    <SearchHeader :onAction="onSearch" :autoUpdate="true" />
    <v-container fluid grid-list-lg>
      <template v-if="selectedRendering == 0">
        <div v-if="filteredFavorites.length">
          <h3 class="grpHeader" :style="tableHeaderStyle">Favorites</h3>
          <v-divider></v-divider>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4 lg3 xl2 v-for="customer in filteredFavorites" :key="customer.id">
              <CustomerCard :customer="customer" :goToCustomer="goToCustomer" :setFav="setFav" />
            </v-flex>
          </v-layout>
        </div>

        <div v-for="(grp, i) in filteredCustomers" :key="i">
          <h3 class="grpHeader" :style="tableHeaderStyle">{{ i }}</h3>
          <v-divider></v-divider>
          <v-layout row wrap>
            <v-flex xs12 sm6 md4 lg3 xl2 v-for="customer in grp" :key="customer.id">
              <CustomerCard :customer="customer" :goToCustomer="goToCustomer" :setFav="setFav" />
            </v-flex>
          </v-layout>
        </div>
      </template>
      <v-data-table v-else :headers="headers" :items="customers" :items-per-page="50" :search="search" @click:row="goToCustomer">
        <template v-slot:no-data>
          {{ $t("common.list.noResults") }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getColor } from "@/services/theme";
import axios from "axios";
import { formatMixin, formMixin, listMixin } from "@/helpers/mixins";

import SearchHeader from "@/components/_common/searchheader";

import CustomerCard from "./customercard";

export default {
  mixins: [formatMixin, formMixin, listMixin],

  data: function() {
    return {
      search: "",
      searchCards: "",
      headers: [
        { text: this.$i18n.t("customers.list.name"), value: "name" },
        { text: this.$i18n.t("customers.list.email"), value: "email" },
        { text: this.$i18n.t("customers.list.email2"), value: "email2" },
        { text: this.$i18n.t("customers.list.phone"), value: "phone" },
        { text: this.$i18n.t("customers.list.mobile"), value: "mobile" },
        { text: this.$i18n.t("customers.list.mobile2"), value: "mobile2" },
        { text: this.$i18n.t("customers.list.seller"), value: "seller.name" },
        { text: this.$i18n.t("customers.list.appActivated"), value: "appActivated" },
        
      ],
      customers: [],
    };
  },

  components: {
    SearchHeader,
    CustomerCard,
  },

  props: {
    projectId: {
      type: String,
      default: () => {},
    },
    selectedRendering: {
      type: Number,
      default: () => 0,
    },
  },

  computed: {
    ...mapGetters(["user"]),
    tableHeaderStyle: function() {
      return `color: ${getColor("accent")}`;
    },
    filteredFavorites() {
      let list = this.customers.filter((c) => c.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 && c.fav);
      return list;
    },
    filteredCustomers() {
      let list = this.customers.filter((c) => c.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 && !c.fav);
      list.forEach((c) => (c.grp = c.name.substring(0, 1).toUpperCase()));
      let grp = this.groupBy(list, "grp");
      return grp;
    },
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/customers`)
        .then((response) => {
          let customers = response.data;
          if (this.user.profile.favCustomers) {
            this.user.profile.favCustomers.forEach((id) => {
              let c = customers.find((c) => c.id == id);
              if (c) {
                c.fav = true;
              }
            });
          }
          this.customers = customers;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    onSearch: function(query) {
      this.search = query;
    },

    goToCustomer: function(customer) {
      this.$router.push(`/customers/${customer.id}`);
    },

    setFav: function(c) {
      let customer = this.customers.find((cus) => cus.id == c.id);
      if (customer) {
        if (customer.fav) {
          customer.fav = false;
        } else {
          customer.fav = true;
        }

        this.$set(
          this.customers,
          this.customers.findIndex((cus) => cus.id == c.id),
          customer
        );

        this.saveFavorites();
      }
    },

    saveFavorites: function() {
      let data = { ...this.user };
      data.profile.favCustomers = this.customers.filter((c) => c.fav).map((c) => c.id);
      this.$store
        .dispatch("setUser", data)
        .then(() => {})
        .catch((e) => {
          this.$store.dispatch("showSnackMessage", { message: e });
        });
    },
  },
};
</script>
<style scoped>
.grpHeader {
  margin-top: 1rem;
  color: rgb(103, 58, 183);
}
.card-outter {
  position: relative;
  padding-bottom: 1rem;
}
.card-actions {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
