<template>
  <v-toolbar dark size="48">
    <v-btn icon dark @click="onClose()" :tabIndex="tabIndex + 1">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn color="lime" text @click="onSave(refKey)" :tabIndex="tabIndex">{{ $t('common.form.save') }}</v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import { formatMixin, formMixin } from "@/helpers/mixins";

export default {
  name: "PanelHeader",

  mixins: [formatMixin, formMixin],

  data() {
    return {};
  },

  props: {
    title: {
      type: String,
      default: () => ""
    },
    refKey: {
      type: String,
      default: () => ""
    },
    onSave: {
      type: Function
    },
    tabIndex: {
      type: Number,
      default: () => -1
    },
    onClose: {
      type: Function
    }
  },

  mounted() {
    let self = this;
    window.addEventListener("keyup", function(event) {
      if (event.keyCode === 27) {
        self.onClose();
      }
    });
  },

  methods: {}
};
</script>
