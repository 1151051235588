<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-model="dateFormatted" :label="label" @blur="onBlur()" prepend-icon="mdi-calendar" :tabIndex="tabIndex" v-on="on"></v-text-field>
    </template>
    <v-date-picker ref="picker" v-model="date" :max="max" :min="min" @input="close" show-week first-day-of-week="1" locale="de"></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment-timezone";

export default {
  data: () => ({
    date: null,
    dateFormatted: null,
    menu: false,
  }),
  props: {
    onClose: {
      type: Function,
    },
    label: {
      type: String,
      default: "Date picker",
    },
    tabIndex: {
      type: Number,
      default: 1,
    },
    min: {
      type: String,
      default: () => null,
    },
    max: {
      type: String,
      default: () => null,
    },
    model: {
      type: String,
      default: () => "",
    },
  },
  computed: {},
  watch: {
    menu(val) {
      if (this.min && this.max) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    model(val) {
      if (val) {
        let a = new Date(Date.parse(val));
        this.date = `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
      } else {
        this.date = "";
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onBlur: function() {
      this.date = this.parseDate(this.dateFormatted);
      let dt = this.getDate();

      if (!this.menu && dt instanceof Date) {
        this.onClose(dt);
      }
    },
    close: function() {
      this.menu = false;
      let dt = this.getDate();

      //if (dt instanceof Date) {
        this.onClose(dt);
      //}
    },
    getDate: function() {
      let d = moment(this.date).tz("Europe/Paris");
      return d.format();
    }
  },
};
</script>
